
import { mapState } from 'vuex'
export default {
    layout: 'blank',
    middleware: 'noAuth',
    data: () => ({
        loginFormValid: false,
        rememberMe: false,
        email: '',
        password: '',
        showPassword: false,
        displayGoogleQR: false,
        displayOTP: false,
        googleQRObject: {},
        loginOtp: null,
    }),

    computed: {
        ...mapState({
            tempUser: (state) => state.auth.tempUser,
        }),
        loading() {
            return this.$store.state.auth.loading
        },
        loginFormRule() {
            return {
                email: [
                    (v) =>
                        (!!v && !!v.trim()) ||
                        this.tl('Employee ID is required'),
                ],
                password: [(v) => !!v || this.tl('Password is required')],
            }
        },
    },
    mounted() {
        let rememberEmail = ''
        try {
            const rememberData = this.$cookie.get('remMe')
            if (rememberData) {
                rememberEmail = atob(rememberData)
                this.email = rememberEmail
                this.rememberMe = true
            }
        } catch (error) {}
    },
    methods: {
        async submit(e) {
            this.$refs.refLoginForm.validate()
            if (this.loginFormValid) {
                const formData = {
                    username: this.email,
                    password: this.password,
                }
                const response = await this.$store.dispatch(
                    'auth/login',
                    formData
                )
                if (response) {
                    if (this.rememberMe) {
                        this.$cookie.set('remMe', btoa(this.email))
                    }
                    if (response.qr_code) {
                        this.googleQRObject = JSON.parse(
                            JSON.stringify(response)
                        )
                        this.displayGoogleQR = true
                    } else if (response.status === true) {
                        this.displayOTP = true
                    }
                }
            }
        },
        // Display OTP page for entering OTP
        onAddDisplayGoogleQR() {
            this.displayGoogleQR = false
            this.displayOTP = true
            setTimeout(() => {
                const element =
                    document.getElementsByClassName('otp-field-box--0')[0]
                element.focus()
            }, 300)
        },
        verifyOTP() {
            const formData = {
                otp_code: this.loginOtp,
            }
            this.$store.dispatch('auth/verityOtp', formData)
        },
    },
}
